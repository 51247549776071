import React from 'react';
import './App.css';

import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()
function App({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        {children}
      </div>
    </QueryClientProvider>
  );
}

export default App;
