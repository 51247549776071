import React from "react";

const TitleText = ({ title, color, className, animateText }) => {
  const animationStyle = animateText
    ? {
        animation: "typingEffect 2s steps(50, end) forwards",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "inline-block",
      }
    : {};

  return (
    <>
      {animateText && (
        <style>
          {`
            @keyframes typingEffect {
              from { width: 0; }
              to { width: 100%; }
            }
          `}
        </style>
      )}
      <span
        className={`text-2xl text-[${color}] ${className}`}
        style={animationStyle}
      >
        {title}
      </span>
    </>
  );
};

export default TitleText;
