import React, { useEffect, useState, useRef } from "react";

import InputField from "../../atoms/InputField";
import OnDeviceIndicator from "../../molecules/OnDeviceIndicatorMolecule";
import DropdownMenu from "../../molecules/DropDownMolecule";
import "./loading.css";
import { enterPressed } from "../../../atoms/misc.atom";
import { useRecoilState } from "recoil";
import { noteTitle, } from "../../../atoms/note.atom";

const TitleInputComponent = ({
  placeholder,
  handleKeyboardEvents,
  onChange,
  value,
  dropdownObject,
  saveMode,
  handleDropDownChange,
  loading = false,
}) => {
  const [showPressEnter, setShowPressEnter] = useState(false);
  const lastKeyUpRef = useRef(Date.now());
  const [IsEnterPressed, setIsEnterPressed] = useRecoilState(enterPressed);
  const [currentNoteTitle, setCurrentNoteTitle] = useRecoilState(noteTitle);
  const timerRef = useRef(null); // Ref to hold the timer

  useEffect(() => {
    if (IsEnterPressed || currentNoteTitle.length <= 1) {
      setShowPressEnter(false); // Set showPressEnter to false
      clearInterval(timerRef.current); // Clear the timer
    } else {
      timerRef.current = setInterval(() => {
        if (Date.now() - lastKeyUpRef.current >= 1000) {
          setShowPressEnter(true);
        }
      }, 1000);
    }

    return () => clearInterval(timerRef.current); // Clean up the interval on component unmount
  }, [IsEnterPressed, currentNoteTitle.length]);

  const handleKeyboardUpEvents = () => {
    lastKeyUpRef.current = Date.now(); // Update the last key up event time
    if (!IsEnterPressed && currentNoteTitle.length > 1) {
      setShowPressEnter(false); // Reset the Press Enter prompt
    }
  };

  return (
    <div
      className={`relative flex md:w-[100%] border-2 outline-none shadow-lg rounded-md justify-center items-center ${
        loading
          ? "border-[2.3px] rounded-xl color-changing-border"
          : "border-[#20497f]"
      }`}
    >
      {saveMode === "offline" && <OnDeviceIndicator />}
      <div className="flex flex-grow relative">
        <InputField
          value={value}
          onChange={(e) => {
            onChange(e);
            setCurrentNoteTitle(e.target.value); // Sync input with Recoil state for currentNoteTitle
          }}
          placeholder={placeholder}
          handleKeyboardEvents={handleKeyboardEvents}
          handleKeyboardUpEvents={handleKeyboardUpEvents}
        />
        {saveMode === "online" && showPressEnter && !IsEnterPressed && (
          <span className="invisible md:visible font-montserrat absolute bg-gray-200 px-1.5 py-0.5 rounded-sm text-xs text-gray-900 top-0.5 right-0.5">
            Press Enter To Fetch Title
          </span>
        )}
      </div>

      {saveMode === "online" && (
        <DropdownMenu
          dropdownObject={dropdownObject}
          handleDropdownChange={handleDropDownChange}
        />
      )}
    </div>
  );
};

export default TitleInputComponent;
