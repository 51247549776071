import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const InfoMessageComponent = ({ typeOfMessage, message }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (typeOfMessage !== "") {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000); // Adjust the timeout as needed

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [typeOfMessage]);

  // Function to determine background color based on message type
  const getBackgroundColor = (type) => {
    switch (type) {
      case "success":
        return "#20497f"; // Dark blue for success
      case "error":
        return "#d32f2f"; // Red for error
      case "warning":
        return "#ff9800"; // Yellow for warning
      default:
        return "#333"; // Default color
    }
  };

  const alertStyle = (type) => ({
    fontFamily: "Montserrat",
    fontWeight: "bold",
    backgroundColor: getBackgroundColor(type), // Set background color based on type
    color: "#fff", // White text
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)", // Shadow
  });

  let myAlert = "";
  switch (typeOfMessage) {
    case "error":
      myAlert = (
        <Alert variant="filled" severity="error" sx={alertStyle(typeOfMessage)}>
          {message}
        </Alert>
      );
      break;
    case "success":
      myAlert = (
        <Alert
          variant="filled"
          severity="success"
          sx={alertStyle(typeOfMessage)}
        >
          {message}
        </Alert>
      );
      break;
    case "warning":
      myAlert = (
        <Alert
          variant="filled"
          severity="warning"
          sx={alertStyle(typeOfMessage)}
        >
          {message}
        </Alert>
      );
      break;
    default:
      break;
  }

  return (
    <Stack sx={{ width: "max-content", fontFamily: "Montserrat" }} spacing={2}>
      {isVisible ? myAlert : ""}
    </Stack>
  );
};

export default InfoMessageComponent;
