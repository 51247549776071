import React from "react";
import HeaderComponent from "../commonComponent/HeaderComponent";
import ButtonComponent from "../commonComponent/ButtonComponent";
function NotFound() {
  return (
    <div>
      <HeaderComponent
        Title="Netpad.click"
        color="#20497f"
        padding={"p-[10px_15px]"}
        onClick={() => {
          window.location = "/";
        }}
      />
      <div className="min-h-screen bg-gray-100 flex justify-center text-center py-12 px-4 sm:px-6 lg:px-8 font-montserrat">
        <div className="flex flex-col max-w-md w-full space-y-4 items-center">
          <h2 className="mt-6 text-left text-xl font-extrabold text-gray-900 font-montserrat border-2 border-[#20497f] outline-none shadow-xl p-2 rounded-md w-full">
            404 - PAGE NOT FOUND
          </h2>
          <div className="flex flex-row m-0 p-0 mt-0 justify-start items-start w-full gap-2 pl-5">
            <ButtonComponent
              buttonName=""
              backGroundColor="bg-[#e9c46a]"
              textColor="text-black"
              hoverColor="hover:bg-[#ffbe0b]"
            />
            <ButtonComponent
              buttonName=""
              backGroundColor="bg-[#5072A7]"
              textColor="text-white"
              hoverColor="hover:bg-[#213553]"
            />
            <ButtonComponent
              buttonName=""
              backGroundColor="bg-[#2a9d8f]"
              textColor="text-white"
              hoverColor="hover:bg-[#049333]"
            />
            <ButtonComponent
              buttonName=""
              backGroundColor="bg-[#e76f51]"
              textColor="text-white"
              hoverColor="hover:bg-[#D10000]"
            />
          </div>

          <div className="flex flex-col gap-4 bg-white h-1/4 items-center justify-evenly shadow-lg rounded-md">
            <p className="mt-2 text-center text-md text-gray-600">
              The page you are looking for does not exist. Please check the URL
              or return to the homepage.
            </p>
            <button
              className="group relative flex justify-center py-2 px-4 border border-transparent text-md font-[500] rounded-md text-white bg-gradient-to-r from-[#2f6bba] to-[#20497f] hover:from-[#20497f] hover:to-[#2f6bba] focus:outline-[#fff] focus:ring-2 focus:ring-offset-2 focus:ring-[#fff] shadow-md hover:border-[#fff]"
              onClick={() => {
                window.location = "/";
              }}
            >
              Create a Note
            </button>
          </div>
        </div>
      </div>
      <footer className="fixed bottom-0 inset-x-0 bg-white z-10 font-sen">
        <div className="flex justify-center items-center p-1">
          <p className="text-gray-500 text-md">
            © 2023{" "}
            <mark className="text-[#20497f] bg-inherit">Netpad.click.</mark> All
            rights reserved.{" "}
            <a
              href="mailto:communications@netpad.click"
              target="_blank"
              className="text-[#20497f] underline"
            >
              Mail
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default NotFound;
