import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "socket.io-client";
import { useRecoilState, useRecoilValue } from "recoil";
import { noteExpiryAtom } from "../../atoms/expiry.atom";
import { saveModeAtom, viewRecentModeAtom } from "../../atoms/modes.atom";
import { noteTitle, isLiveMode } from "../../atoms/note.atom";
import { enterPressed } from "../../atoms/misc.atom";
import ButtonComponent from "../commonComponent/ButtonComponent";
import HeaderComponent from "../commonComponent/HeaderComponent";
import InfoMessageComponent from "../commonComponent/InfoMessageComponent";
import QuillComponent from "../commonComponent/QuillComponent";
import BulletListCardComponent from "../commonComponent/BulletListCardComponent";
import InfoBannerComponent from "../commonComponent/InfoBannerComponent";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Header from "../molecules/Header";
import TitleInputComponent from "../organisms/TitleInputComponent/index";
import ToggleSwitch from "../atoms/LiveToggle";
import {
  DeleteNoteByTitle,
  RetrieveNoteByName,
  StoreNoteDataObject,
  getAllStoredNoteTitles,
} from "../../repository/note.repository";
import { deleteNote, getNote, updateNote } from "../../api/notes.api";

import SplashScreenAnimation from "../atoms/SplashScreen/SplashScreen";

const HomeScreen = () => {
  const [typeOfMessage, setTypeOfMessage] = useState("");
  const [message, setMessage] = useState("");
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");
  const [saveButtonName, setSaveButtonName] = useState("Save");
  const [recentNotes, setRecentNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstVisit, setFirstVisit] = useState(true); // State to manage first visit

  const [noteExpiry, setNoteExpiry] = useRecoilState(noteExpiryAtom);
  const recentViewNoteMode = useRecoilValue(viewRecentModeAtom);
  const [saveMode, setSaveMode] = useRecoilState(saveModeAtom);
  const [title, setTitle] = useRecoilState(noteTitle);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [IsEnterPressed, setIsEnterPressed] = useRecoilState(enterPressed);
  const [isLive, setIsLive] = useRecoilState(isLiveMode);

  const dropdownOptions = [
    { label: "♾️", value: "never" },
    { label: "5m", value: "5m" },
    { label: "1h", value: "1h" },
    { label: "24h", value: "24h" },
  ];

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    try {
      const newSocket = io("https://api.netpad.click", {
        transports: ["websocket"],
        upgrade: false,
      });
      newSocket.on("connect", () => {
        console.log("Connected to socket");
      });

      newSocket.on("disconnect", () => {
        console.log("Disconnected from socket");
      });

      setSocket(newSocket);

      return () => newSocket.close();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("note-update", (data) => {
        setContent(data);
      });
    }
  }, [socket]);

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("firstVisit");
    if (!isFirstVisit) {
      localStorage.setItem("firstVisit", "true");
      setFirstVisit(true);
      setTimeout(() => {
        setFirstVisit(false);
      }, 1500); // Display loading animation for 3 seconds
    } else {
      setFirstVisit(false);
    }
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    if (subject.startsWith("#")) {
      setSaveMode("offline");
    } else {
      setSaveMode("online");
    }
  }, [subject]);

  useEffect(() => {
    let storedRecentTitles =
      JSON.parse(localStorage.getItem("recentTitles")) || [];
    setRecentNotes(storedRecentTitles);

    const fetchAndSetSubject = async () => {
      const url = new URL(window.location.href);
      let title = url.searchParams.get("title");
      if (title) {
        title = String(title).toLocaleLowerCase();
        setSubject(title);
        setTitle(title);
        await viewButtonHandler(title);
      }
    };

    fetchAndSetSubject();
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleSaveOnCtrlS);
    return () => {
      window.removeEventListener("keydown", handleSaveOnCtrlS);
    };
  }, [subject, content]);

  const showMessage = (type, message) => {
    setTypeOfMessage(type);
    setMessage(message);
    setTimeout(() => {
      setTypeOfMessage("");
      setMessage("");
    }, 2000);
  };

  const saveOfflineNote = async (subject, content) => {
    setSaveMode("offline");
    await StoreNoteDataObject({ title: subject, content });
    const offlineNotesTitles = await getAllStoredNoteTitles();
    setRecentNotes(offlineNotesTitles);
    showMessage("success", "Note Saved Locally");
  };

  const saveOnlineNote = async (subject, content, noteExpiry) => {
    const updateNoteData = {
      title: subject.toLowerCase().trim(),
      content,
      expiry: noteExpiry,
    };
    const response = await updateNote(updateNoteData);
    if (response.success) {
      showMessage("success", "Note Saved");

      const storedRecentTitles =
        JSON.parse(localStorage.getItem("recentTitles")) || [];
      const updatedRecentTitles = Array.from(
        new Set([...storedRecentTitles, subject])
      ).slice(-10);
      localStorage.setItem("recentTitles", JSON.stringify(updatedRecentTitles));
      setRecentNotes(updatedRecentTitles);
    }
  };

  const saveButtonHandler = async () => {
    try {
      if (!String(subject).trim()) {
        showMessage("error", "No Title");
        return;
      }
      setLoading(true);

      if (subject.startsWith("#")) {
        await saveOfflineNote(subject, content);
      } else {
        await saveOnlineNote(subject, content, noteExpiry);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const viewButtonHandler = async (title = null) => {
    setLoading(true);
    if (title ? title.startsWith("#") : subject.startsWith("#")) {
      setSaveMode("offline");
      let storedNote = await RetrieveNoteByName(title ?? subject);
      if (!storedNote) {
        setTypeOfMessage("error");
        setMessage("Note Not Found");
        setTimeout(() => {
          setTypeOfMessage("");
          setMessage("");
        }, 2000);
        setLoading(false);
        return;
      }

      setContent(storedNote?.content);
      setLoading(false);
      return;
    }
    if (!String(title ?? subject)) {
      setLoading(false);
      setTypeOfMessage("error");
      setMessage("Empty Title");
      setTimeout(() => {
        setTypeOfMessage("");
        setMessage("");
      }, 2000);
    }

    try {
      let noteData = await getNote(String(title ?? subject));
      setContent(noteData?.content);
      setTypeOfMessage("success");
      setMessage("Note Fetched");
      setLoading(false);
      setTimeout(() => {
        setTypeOfMessage("");
        setMessage("");
      }, 2000);
    } catch (error) {
      setTypeOfMessage("error");
      setMessage("Note Not Found");
      setTimeout(() => {
        setTypeOfMessage("");
        setMessage("");
      }, 2000);
      setLoading(false);
    }
  };

  const deleteButtonHandler = async () => {
    try {
      if (!String(subject).trim()) {
        showMessage("error", "No Title to delete");
        return;
      }
      setLoading(true);
      socket.emit("leave-room", title);
      setIsLive(false);

      if (subject.startsWith("#")) {
        await DeleteNoteByTitle(subject);
        const offlineNotesTitles = await getAllStoredNoteTitles();
        setRecentNotes(offlineNotesTitles);
      } else {
        await deleteNote(subject);
        const storedRecentTitles =
          JSON.parse(localStorage.getItem("recentTitles")) || [];
        const updatedRecentTitles = storedRecentTitles.filter(
          (title) => title !== subject
        );
        localStorage.setItem(
          "recentTitles",
          JSON.stringify(updatedRecentTitles)
        );
        setRecentNotes(updatedRecentTitles);
        removeQueryParamInURL("title");
      }

      setContent("");
      setSubject("");
      setTitle("");
      showMessage("success", "Note Deleted");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const cleanButtonHandler = () => {
    socket.emit("leave-room", title);
    setIsLive(false);
    setContent("");
    setSubject("");
    setTitle("");
    setSaveButtonName("Save");
    setSaveMode("online");
    removeQueryParamInURL("title");
  };

  const handleSaveOnCtrlS = (e) => {
    if ((e.ctrlKey || e.metaKey) && (e.key === "s" || e.key === "S")) {
      e.preventDefault();
      saveButtonHandler();
    }
  };

  const handleKeyboardEvents = (e) => {
    if (e.key === "Enter") {
      setIsEnterPressed(true);
      viewButtonHandler();
    }

    if ((e.ctrlKey || e.metaKey) && (e.key === "s" || e.key === "S")) {
      e.preventDefault();
      saveButtonHandler();
    }
  };

  const setQueryParamInURL = (queryKey, queryValue) => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.set(
      String(queryKey).toLocaleLowerCase(),
      String(queryValue).toLocaleLowerCase()
    );
    window.history.pushState({}, "", newUrl);
  };

  const removeQueryParamInURL = (queryKey) => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete(String(queryKey).toLocaleLowerCase());
    window.history.pushState({}, "", newUrl);
  };

  const handleOnChangeTitle = (e) => {
    setIsLive(false);
    socket.emit("leave-room", title);
    setSubject(String(e.target.value).toLowerCase());
    setTitle(String(e.target.value).toLowerCase());
    if (e.target.value.startsWith("#")) {
      setSaveButtonName("Save 🔏");
      setSaveMode("offline");
    } else {
      setSaveButtonName("Save");
      setSaveMode("online");
      setQueryParamInURL("title", e.target.value);
    }
  };

  const handleOnChangeContent = (e) => {
    setContent(e);
    if (socket && title && isLive) {
      socket.emit("note-update", { title, content: e });
    }
  };

  const handleRecentNoteTitleClick = (newTitle) => {
    socket.emit("leave-room", title);
    setIsLive(false);
    setContent(null);
    setSubject(newTitle);
    setTitle(newTitle);
    viewButtonHandler(newTitle);
  };

  const onToggleViewRecentSwitchHandler = async () => {
    if (recentViewNoteMode == "offline") {
      let offlineNotesTitles = await getAllStoredNoteTitles();
      setRecentNotes(offlineNotesTitles);
    }

    if (recentViewNoteMode == "online") {
      let storedRecentTitles =
        JSON.parse(localStorage.getItem("recentTitles")) || [];
      setRecentNotes(storedRecentTitles);
    }
  };

  const handleInputTitleDropdownChange = (e) => {
    setNoteExpiry(e.target.value);
  };

  const goLiveHandler = (goLiveBool) => {
    if (socket && title) {
      if (goLiveBool) {
        socket.emit("join-room", title);
        setIsLive(true);
      } else {
        socket.emit("leave-room", title);
        setIsLive(false);
      }
    }
  };

  if (firstVisit) {
    return <SplashScreenAnimation />;
  }

  return (
    <div className="h-full w-full">
      <div className="bg-[#f8f8f8] shadow-md h-15">
        <Header
          title="Netpad.click"
          color="#20497f"
          padding={"p-[10px_15px]"}
          onClick={toggleDrawer(true)}
        />
      </div>
      <div className="md:p-10 p-4 w-[100%]">
        <div className="w-full md:flex md:w-[70%] gap-[10px] md:justify-between">
          <TitleInputComponent
            value={subject}
            placeholder="A CRISPY TITLE"
            handleKeyboardEvents={handleKeyboardEvents}
            onChange={handleOnChangeTitle}
            dropdownObject={dropdownOptions}
            saveMode={saveMode}
            handleDropDownChange={handleInputTitleDropdownChange}
            loading={loading}
          />

          <div className="mt-4 md:mt-0 flex gap-[10px] items-center">
            <ButtonComponent
              buttonName={saveMode == "offline" ? "Save 🔏" : "Save"}
              backGroundColor="bg-[#049333]"
              textColor="text-white"
              hoverColor="hover:bg-[#007515]"
              onClick={saveButtonHandler}
            />
            <ButtonComponent
              buttonName="View"
              backGroundColor="bg-[#5072A7]"
              textColor="text-white"
              hoverColor="hover:bg-[#213553] hover:shadow-md"
              onClick={() => viewButtonHandler()}
            />
            <ButtonComponent
              buttonName="Clear"
              backGroundColor="bg-[#e9c46a]"
              textColor="text-black"
              hoverColor="hover:bg-[#ffbe0b] hover:shadow-md"
              onClick={cleanButtonHandler}
            />
            <ButtonComponent
              buttonName="Delete"
              backGroundColor="bg-[#e76f51]"
              textColor="text-black"
              hoverColor="hover:bg-[#c95133] hover:shadow-md"
              onClick={deleteButtonHandler}
            />
          </div>
        </div>
        <div className="mt-5 md:flex md:place-items-start md:gap-[15px] block font-mono">
          <div className="md:w-[70%]">
            <QuillComponent
              value={content}
              onChange={handleOnChangeContent}
              goLiveHandler={goLiveHandler}
            />
          </div>

          <BulletListCardComponent
            listItems={recentNotes}
            listItemClickHandler={handleRecentNoteTitleClick}
            onToggleSwitchHandler={onToggleViewRecentSwitchHandler}
          />
        </div>

        <div className="font-montserrat z-50 fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-10">
          <InfoMessageComponent
            typeOfMessage={typeOfMessage}
            message={message}
          />
        </div>
        <footer className="fixed bottom-0 inset-x-0 bg-white z-10 font-sen">
          <div className="flex justify-center items-center p-1">
            <p className="text-gray-500 text-md">
              © 2023{" "}
              <mark className="text-[#20497f] bg-inherit">Netpad.click.</mark>{" "}
              All rights reserved.{" "}
              <a
                href="mailto:communications@netpad.click"
                target="_blank"
                className="text-[#20497f] underline"
              >
                Mail
              </a>
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default HomeScreen;
