import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './App';
import HomeScreen from './components/screen/HomeScreen';
import NotFound from './components/screen/NotFound';
import { initIndexedDB } from './repository/note.repository';

initIndexedDB()
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <RecoilRoot>
          <Router>
            <App>
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="*" element={<NotFound />} /> {/* Catch all route */}
              </Routes>
            </App>
          </Router>
        </RecoilRoot>
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((error) => {
    // Handle error
    console.error("Failed to initialize database", error);
  });



// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
      console.log('Service Worker registration successful with scope: ', registration.scope);
    }, function (err) {
      console.log('Service Worker registration failed: ', err);
    });
  });
}
