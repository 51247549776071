import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRecoilState } from "recoil";
import "../../App.css";
import { templateAtom } from "../../atoms/templates.atom";
import {
  convertHTMLToPlainText,
  getAllTemplateKeys,
  retrieveTemplateByKeyName,
} from "../../repository/note.repository";
import CustomToolbar from "./CustomToolbat";

const QuillComponent = ({ value, onChange, loading = false, goLiveHandler }) => {
  const [templates, setTemplates] = useRecoilState(templateAtom);

  const quillRef = useRef(null);

  useEffect(() => {
    let commands = ["/clear", "->", ":cry", ":swag", ":done"];
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.on("text-change", function (delta, oldDelta, source) {
        if (source === "user") {
          let curPos = editor.getSelection(true).index;
          let text = editor.getText();

          const processCommand = async (command, index) => {
            editor.deleteText(index, command.length);
            if (command === "/clear") {
              editor.deleteText(0, editor.getLength());
            } else if (command === "->") {
              editor.clipboard.dangerouslyPasteHTML(index, "→");
            } else if (command === ":cry") {
              editor.clipboard.dangerouslyPasteHTML(index, "😭");
            } else if (command === ":swag") {
              editor.clipboard.dangerouslyPasteHTML(index, "😎");
            } else if (command === ":done") {
              editor.clipboard.dangerouslyPasteHTML(index, "✅");
            }
            editor.setSelection(index + 1);
          };

          for (const command of commands) {
            let index = text.lastIndexOf(command, curPos);
            if (index !== -1) {
              processCommand(command, index);
            }
          }
        }
      });
    }
  }, []);

  return (
    <div>
      <CustomToolbar onToggle={goLiveHandler} />
      <ReactQuill
        ref={quillRef}
        className={`font-montserrat h-[60vh] ${loading ? "animateQuill" : ""}`}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={QuillComponent.modules}
        formats={QuillComponent.formats}
        placeholder={
          "Write something legendary 🖋️......." +
          "\n" +
          "\n" +
          "Tips: " +
          "\n" +
          "\n" +
          "- You can also use ctrl/cmd + s to save your note." +
          "\n" +
          "\n" +
          "- Add # to title and store notes on-device (completely private)" +
          "\n" +
          "\n" +
          "\n" +
          "\n" +
          "\n" +
          "\n" +
          "- New Interface & features on the way 🚀"
        }
      />
    </div>
  );
};

QuillComponent.modules = {
  toolbar: {
    container: "#toolbar",
  },
  clipboard: {
    matchVisual: true,
  },
};

QuillComponent.formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "code-block",
  "align",
  "direction",
  "video",
  "customCheckbox",
];

export default QuillComponent;
