import React from "react";

const ButtonComponent = ({
  buttonName = "Default",
  onClick = () => {},
  backGroundColor = "bg-black",
  textColor = "text-white",
  hoverColor,
  style,
  className,
}) => {
  return (
    <button
      className={`p-[7px_11px] w-max h-max ${backGroundColor} ${textColor} ${hoverColor} rounded-md font-montserrat font-[500] ${className}`}
      onClick={onClick}
    >
      {buttonName}
    </button>
  );
};

export default ButtonComponent;
