import React, { useEffect, useState } from "react";
import { viewRecentModeAtom } from "../../atoms/modes.atom";
import { useRecoilState } from "recoil";

const Switcher11 = ({
  modes = [
    { title: "one", value: "one" },
    { title: "two", value: "two" },
  ],
  onSwitchHandler,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [viewRecentModeMode, setViewRecentMode] =
    useRecoilState(viewRecentModeAtom);

  useEffect(() => {
    setViewRecentMode(isChecked ? "online" : "offline");
    onSwitchHandler(); // Assuming this needs to be called every time the mode changes
  }, [isChecked]);

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  return (
    <label className="flex font-montserrat themeSwitcherTwo shadow-card relative cursor-pointer select-none items-center justify-between rounded-md bg-white p-1">
      <input
        type="checkbox"
        className="sr-only"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span
        className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${
          !isChecked ? "text-primary bg-[#f4f7ff]" : "text-body-color"
        }`}
      >
        <svg
          width="16"
          height="16"
          className="visible md:invisible lg:visible mr-[6px] fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          id="Cloud"
        >
          <path fill="none" d="M0 0h48v48H0z"></path>
          <path
            d="M38.71 20.07C37.35 13.19 31.28 8 24 8c-5.78 0-10.79 3.28-13.3 8.07C4.69 16.72 0 21.81 0 28c0 6.63 5.37 12 12 12h26c5.52 0 10-4.48 10-10 0-5.28-4.11-9.56-9.29-9.93zM20 34l-7-7 2.83-2.83L20 28.34l10.35-10.35 2.83 2.83L20 34z"
            fill="black"
            fillRule="evenodd"
            clipRule="evenodd"
            className="color000000 svgShape"
          ></path>
        </svg>

        {modes[0]?.title}
      </span>
      <span
        className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${
          isChecked ? "text-primary bg-[#f4f7ff]" : "text-body-color"
        }`}
      >
        <svg
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
          className="visible md:invisible lg:visible mr-[6px] fill-current"
          viewBox="0 0 512 512"
          id="folder"
        >
          <path d="M472 176H40c-4.411 0-8 3.589-8 8v239c0 13.317 11.683 25 25 25h400c12.683 0 23-11.215 23-25V184c0-4.411-3.589-8-8-8zM457 96H215c-2.791 0-4.271-.585-6.094-2.408l-22.501-22.501-.168-.162C181.365 66.333 177.361 64 169 64H57c-13.785 0-25 10.317-25 23v74.376A23.885 23.885 0 0140 160h432c2.805 0 5.496.488 8 1.376V119c0-13.327-9.673-23-23-23z"></path>
        </svg>
        {modes[1]?.title}
      </span>
    </label>
  );
};

export default Switcher11;
