import { atom } from "recoil";

export const noteTitle = atom({
  key: "note-title", // unique ID (with respect to other atoms/selectors)
  default: "",
});

export const isLiveMode = atom({
  key: "live-mode",
  default: false
})
