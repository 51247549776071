import React from "react";
import ToggleSwitch from "../atoms/LiveToggle";

const CustomToolbar = ({ onToggle }) => (
  <div id="toolbar" className="font-montserrat flex flex-wrap items-center">
    <select className="ql-header">
      <option value="1"></option>
      <option value="2"></option>
      <option selected></option>
    </select>
    <select className="ql-font"></select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <button className="ql-blockquote"></button>
    <button className="ql-code-block"></button>
    <select className="ql-align"></select>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <button className="ql-indent" value="-1"></button>
    <button className="ql-indent" value="+1"></button>
    <button className="ql-link"></button>
    <button className="ql-image"></button>
    <button className="ql-video"></button>
    <div className="ml-auto items-center space-x-2 mt-2 md:mt-0 hidden md:flex font-[500]">
      <span className="mr-1">Go Live</span>
      <ToggleSwitch onToggle={onToggle} />
    </div>
  </div>
);

export default CustomToolbar;
