import { atom } from "recoil";

export const saveModeAtom = atom({
    key: 'save-mode',
    default: "online"
});

export const viewRecentModeAtom = atom({
    key: "view-recent-mode",
    default: "online"
})
