import { openDB } from "idb";
import { generateRandomNumber } from "../utils/id.util";

const OBJECT_STORE_NAME = "notes";
const DB_NAME = "netpad-local-db";
const DB_VERSION = 4;

export const initIndexedDB = async () => {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
        db.createObjectStore(OBJECT_STORE_NAME, { keyPath: "id" });
      }
    },
  });
  db.close();
};

export const StoreNoteDataObject = async (dataObject) => {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
        console.log("creating object store");
        db.createObjectStore(OBJECT_STORE_NAME, { keyPath: "id" });
      }
    },
  });
  const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite");
  const objectStore = transaction.objectStore(OBJECT_STORE_NAME);
  const objectToStore = { ...dataObject, id: dataObject.title }; // generateRandomNumber() could be used here instead of a fixed value

  // console.log('objectToStore:', objectToStore);

  try {
    await objectStore.put(objectToStore);
    // console.log('Data stored successfully.');
  } catch (error) {
    console.error("Transaction error:", error);
  }

  db.close();
  // console.log('Transaction completed: database modification finished.');
};

export const RetrieveNoteByName = async (id) => {
  const db = await openDB(DB_NAME, DB_VERSION);

  if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
    console.error(`Object store ${OBJECT_STORE_NAME} not found.`);
    db.close();
    return null;
  }

  const transaction = db.transaction(OBJECT_STORE_NAME, "readonly");
  const objectStore = transaction.objectStore(OBJECT_STORE_NAME);

  try {
    const objectToRetrieve = await objectStore.get(id);
    db.close();
    return objectToRetrieve;
  } catch (error) {
    console.error("Transaction error:", error);
    db.close();
    return null;
  }
};

export const getAllStoredNoteTitles = async () => {
  const db = await openDB(DB_NAME, DB_VERSION);

  if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
    console.error(`Object store ${OBJECT_STORE_NAME} not found.`);
    db.close();
    return null;
  }

  try {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readonly");
    const objectStore = transaction.objectStore(OBJECT_STORE_NAME);
    const request = await objectStore.getAllKeys();
    db.close();
    return request;
  } catch (error) {
    console.error("Transaction error:", error);
    db.close();
    return null;
  }
};

export const DeleteNoteByTitle = async (id) => {
  const db = await openDB(DB_NAME, DB_VERSION);

  if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
    console.error(`Object store ${OBJECT_STORE_NAME} not found.`);
    db.close();
    return;
  }

  const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite");
  const objectStore = transaction.objectStore(OBJECT_STORE_NAME);

  try {
    await objectStore.delete(id);
    await transaction.done; // This ensures the transaction completes before closing the db
    console.log("Note deleted successfully.");
  } catch (error) {
    console.error("Transaction error:", error);
  }

  db.close();
};

export const convertHTMLToPlainText = (htmlText) => {
  console.log(htmlText);
  // Remove all HTML tags from the text.
  htmlText = htmlText.replace(/<[^>]*>/g, "");

  // Replace all <br> tags with newline characters.
  htmlText = htmlText.replace("<br>", "\n");

  return htmlText;
};

// RetrieveDataObject("/henlo")

// export const getLatestTenEntries = async () => {
//     const DB_NAME = 'netpad-local-db';
//     const OBJECT_STORE_NAME = 'notes-title-history';

//     const db = await openDB(DB_NAME, 1);
//     const transaction = db.transaction(OBJECT_STORE_NAME, 'readonly');
//     const objectStore = transaction.objectStore(OBJECT_STORE_NAME);

//     const cursor = await objectStore.openCursor(null, 'prev'); // Open cursor in reverse order to get latest entries
//     let latestTenEntries = [];
//     while (cursor) {
//         latestTenEntries.push(cursor.value);
//         if (latestTenEntries.length === 10) {
//             break;
//         }
//         cursor.continue();
//     }

//     return latestTenEntries;
// };
