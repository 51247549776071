import React, { useState } from "react";
import ButtonComponent from "../../commonComponent/ButtonComponent";
import { isLiveMode } from "../../../atoms/note.atom";
import { useRecoilState } from "recoil";

const Modal = ({ message, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto w-full">
      <p
        className="mb-6 text-gray-700"
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
      <ButtonComponent
        buttonName="kul 🥶"
        backGroundColor="bg-blue-100"
        textColor="text-white"
        hoverColor="hover:bg-blue-200 hover:shadow-md"
        onClick={onClose}
      />
    </div>
  </div>
);

const ToggleSwitch = ({ onToggle }) => {
  const [isToggled, setIsToggled] = useRecoilState(isLiveMode);
  const [showModal, setShowModal] = useState(false);
  const [firstClick, setFirstClick] = useState(true);

  const handleToggle = () => {
    if (firstClick) {
      setShowModal(true);
      setFirstClick(false);
    } else {
      setIsToggled(!isToggled);
      onToggle(!isToggled);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsToggled(!isToggled);
    onToggle(!isToggled);
  };

  return (
    <>
      {showModal && (
        <Modal
          message="👋 Hey,<br /> <br />Thanks for trying our new collaboration feature! It enables real-time note editing, allowing multiple users to work together seamlessly under the same title.<br /> <br /> Please note that it is still in beta, so you might encounter some issues. 🚧 Also, remember that your notes will not be saved automatically."
          onClose={handleCloseModal}
        />
      )}
      <div
        className={`relative w-12 h-6 flex items-center bg-gray-300 rounded-full cursor-pointer transition-colors duration-300 ${
          isToggled ? "bg-[#4caf50]" : ""
        }`}
        onClick={handleToggle}
      >
        <div
          className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ${
            isToggled ? "translate-x-6" : ""
          }`}
        >
          {isToggled && (
            <div className="absolute top-1/2 left-1/2 w-2 h-2 bg-red-500 rounded-full animate-blink transform -translate-x-1/2 -translate-y-1/2" />
          )}

          {!isToggled && (
            <div className="absolute top-1/2 left-1/2 w-2 h-2 bg-[#20497f] rounded-full animate-blink transform -translate-x-1/2 -translate-y-1/2" />
          )}
        </div>
      </div>
    </>
  );
};

export default ToggleSwitch;
