import React from "react";
// InputField.jsx
const InputField = ({
  value,
  onChange,
  placeholder,
  handleKeyboardEvents,
  handleKeyboardUpEvents,
}) => (
  <input
    value={value}
    onChange={onChange}
    onKeyDown={handleKeyboardEvents}
    onKeyUp={handleKeyboardUpEvents}
    placeholder={placeholder}
    className="ml-[6px] p-2 sm:p-0 sm:ml-1 sm:pl-3 flex-grow pl-1 font-semibold uppercase font-montserrat focus:outline-none"
  />
);

export default InputField;
