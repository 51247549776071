import React from "react";

// OnDeviceIndicator.jsx
const OnDeviceIndicator = () => (
  <div className="absolute z-50 top-[-0.90rem] left-1 bg-[#20497f] text-white px-1.5 py-0.5 rounded-full text-xs">
    On Device
  </div>
);

export default OnDeviceIndicator;
