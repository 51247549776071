import React from "react";
import Switcher11 from "./Switcher";
import ButtonComponent from "./ButtonComponent";
import CanvasDraw from "react-canvas-draw";
import { ReactComponent as OnDeviceIcon } from "../../asset/file-icon.svg";

function BulletListCardComponent({
  listItems,
  listItemClickHandler,
  onToggleSwitchHandler,
}) {
  return (
    <div className="invisible md:visible flex flex-col bg-white max-w-[20%]">
      {/* <button className="font-montserrat">Create a WorkSpace</button> */}
      {/* <button className="p-[3px] w-full rounded-md font-montserrat font-[500] text-black bg-white mb-2 active:shadow-lg border-2 border-[#A52A2A]">
        Create WorkSpace 💼
      </button> */}
      <div className="font-montserrat font-semibold text-lg bg-gray-100 p-2 rounded-t-lg">
        Recent Titles{" "}
      </div>
      <div className="hidden md:block">
        <Switcher11
          modes={[
            { title: "On-Cloud", value: "online" },
            { title: "On-Device", value: "offline" },
          ]}
          onSwitchHandler={onToggleSwitchHandler}
        />
      </div>

      {/* <ul className="list-disc pl-5 pt-2 font-montserrat font-bold text-sm p-2 netpad-boxShadow-top rounded-b-lg mt-2 max-h-[40vh] overflow-y-auto">
        {listItems.map((title) => (
          <li key={title}>
            <button
              className="text-[#20497f] hover:underline uppercase font-bold block text-left overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[50%]"
              onClick={() => listItemClickHandler(title)}
            >
              {title}
            </button>
          </li>
        ))}
      </ul> */}
      <ul className="list-disc pl-5 pt-2 font-montserrat font-bold text-sm p-2 netpad-boxShadow-top rounded-b-lg mt-2 max-h-[40vh] overflow-y-auto">
        {listItems.map((title) => (
          <li key={title}>
            <button
              className="text-[#20497f] hover:underline uppercase font-bold block text-left overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[70%]"
              onClick={() => listItemClickHandler(title)}
            >
              {title.startsWith("#") ? (
                <>
                  <OnDeviceIcon className="mr-2 mb-1 inline-block w-5 h-5 fill-current text-yellow-500" />
                  {title.substring(1)}
                </>
              ) : (
                title
              )}
            </button>
          </li>
        ))}
        {listItems.length == 0 && (
          <p className="text-sm font-normal">
            You can save notes locally (offline) by adding a '#' to the title
          </p>
        )}
      </ul>

      {/* <CanvasDraw
          brushColor="#5072A7"
          brushRadius={2}
          canvasWidth={300}
          canvasHeight={300}
          hideGrid
          lazyRadius={1}
          // hideInterface
          className="mt-5"
          catenaryColor="black"
        /> */}
    </div>
  );
}

export default BulletListCardComponent;
