import React from "react";
import logo from "../../../asset/logo.png";

const LogoImage = ({ className }) => {
  return (
    <img
      src={logo}
      alt="logo"
      className={`cursor-pointer ${className}`}
      width="35px"
    />
  );
};

export default LogoImage;
