import axios from "axios";


/**
 * Updates a note by sending a PUT request to the specified API endpoint.
 * 
 * @async
 * @function
 * @param {Object} data - The data to be updated.
 * @param {string} data.title - The title of the note, converted to lowercase and trimmed.
 * @param {string} data.content - The content of the note.
 * @param {Date} data.expiry - The expiry date/time of the note.
 * 
 * @returns {Promise<Object>} The data returned from the API after the update.
 * 
 * @throws {Error} Throws an error if the API request fails.
 */
export const updateNote = async (data) => {
    try {
        const config = {
            method: "put",
            maxBodyLength: Infinity,
            url: "https://api.netpad.click/api/notes/note",
            headers: {
                "Content-Type": "application/json",
            },
            data,
        };

        const response = await axios(config);
        return response.data;

    } catch (error) {
        throw error;

    }

};

export const getNote = async (title) => {
    try {
        const config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://api.netpad.click/api/notes/note?title=${title}`,
            headers: {},
        };

        const response = await axios(config);
        return response.data.data;

    } catch (error) {
        throw error;

    }

};

export const deleteNote = async (title) => {
    try {
        const config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: `https://api.netpad.click/api/notes/note?title=${title}`,
            headers: {},
        };
        await axios(config)
    } catch (error) {
        throw error

    }

}