import React from "react";
import logo from "../../asset/logo.png";

const HeaderComponent = ({
  Title = "Default Title",
  color = "black",
  padding,
  onClick,
}) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: "Check out this awesome note",
          url: window.location.href,
        });
        console.log("Successful share");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.error("Web Share API not supported in this browser.");
    }
  };

  return (
    <div
      className={`text-2xl min-h-full bold flex items-center justify-between font-sen text-[${color}] + ${padding} gap-2`}
    >
      <div
        className="flex flex-row justify-start align-baseline gap-2 cursor-pointer"
        onClick={onClick}
      >
        <img width="35px" src={logo} alt="logo" className="cursor-pointer" />
        {Title}
      </div>
      <div className="visible">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.0}
          stroke="currentColor"
          className="h-5 w-5 text-[#20497f] cursor-pointer"
          onClick={handleShare}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default HeaderComponent;
