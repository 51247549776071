// src/components/commonComponent/LoadingAnimation.js
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../lottie-files/logo-intro.json";

const SplashScreenAnimation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
      <Lottie options={defaultOptions} height={400} width={420} />
    </div>
  );
};

export default SplashScreenAnimation;
